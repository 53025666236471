import React from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import './login.css'
import { useLocation, useNavigate } from 'react-router-dom'

export const Login = ({itemName}) => {
    const location = useLocation()
    const navigate = useNavigate()
  return (
    <div className="main-container">
        <Button className='back' onClick={()=> {navigate('/')}}>Back</Button>

        <Container className='login-container d-flex justify-content-center align-items-center'>
            <Card className='p-5'>
                <h2 className="text-center">Login for <span className="blue">{location.state.name}</span></h2>
                <p className="lead">Enter your email and password to login</p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" />
              </Form.Group>
              <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter your password" />
              </Form.Group>
              <Button className='btn btn-primary d-block mx-auto mt-2'>Login</Button>
            </Form>
            </Card>
        </Container>
    </div>
  )
}
