import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import { LineWave } from 'react-loader-spinner';
import config from '../../config.json'

import './properties.css'
import { FaMapPin } from 'react-icons/fa'
import KWPropertyCard from '../../components/propertycard/propertycard'
export const Properties = () => {
  const [propertyData, setPropertyData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(config.apiUrl + '/v1/rentals', {
          headers: {
            'xbuildiumclientid': config.apiKeys.buildiumClientId,
            'xbuildiumclientsecret': config.apiKeys.buildiumClientSecret
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPropertyData(data);
          const map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: 0, lng: 0 },
            zoom: 2
          });
          var geocoder = new window.google.maps.Geocoder();
          // Loop through the addresses and geocode them
          data.forEach(function (address) {
            const address1 = address['Address'].AddressLine1 + ',' + address['Address'].AddressLine2 + ',' + address['Address'].AddressLine3 + ',' + address['Address'].City + ',' +
              address['Address'].State + ',' +
              address['Address'].Country + ' - ' +
              address['Address'].PostalCode;

            geocoder.geocode({ address: address1 }, function (results, status) {
              if (status === "OK") {
                new window.google.maps.Marker({
                  map: map,
                  position: results[0].geometry.location,
                  title: address
                });
              } else {
                console.error(
                  "Geocode was not successful for the following reason: " + status
                );
              }
            });
          });
        } else {
          throw new Error('Error fetching property data');
        }
      } catch (error) {
        console.error('Error fetching property data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <KWNavbar />
      <Container className='mt-5 pt-5'>
        <Row>
          <Col id="map" className='mt-4 col-sm-12 col-md-5 propertiesMap'></Col>
          <Col sm={12} md={7} className='mt-4 md-lg-0'>
            <Form.Control placeholder='Search Address'></Form.Control>
            <div className='mt-4 d-flex justify-content-between'>
              <Button variant='outline-primary' className='me-2 mb-2 mb-md-0' size='lg'>Advance Search</Button>
              <Button variant='primary' size='lg'>Search</Button>
            </div>
            <Row className='properties-container mt-4'>
              {
                propertyData.length > 0 ? (
                  propertyData.map((property) => (
                    <Col sm={12} lg={6}><KWPropertyCard mapCard={true} currentProperty={property} /></Col>
                  ))
                ) : (
                  <div className="d-flex justify-content-center">
                    <LineWave
                      height="200px"
                      width="300px"
                      color="#0586f0"
                      ariaLabel="line-wave"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                    />
                  </div>
                )
              }
            </Row>
          </Col>
        </Row>
      </Container>
      <KWFooter />
    </>
  )
}
