import './App.css';
import { Home } from './pages/Home/Home';
import { Browse } from './pages/Browse/Browse';
import { Route, Routes } from 'react-router-dom';
import { Properties } from './pages/Properties/properties';
import { Commercial } from './pages/Commercial/Commercial';
import { Students } from './pages/Students/Students';
import { Contactus } from './pages/Contactus/contactus';
import { Login } from './pages/Login/login';
import { Team } from './pages/Team/team';
import { GeneralApllication } from './pages/GeneralAplication/GeneralApllication';
import { StudentApplication } from './pages/StudentApplication/StudentApplication';
import { Showing } from './pages/Showing/Showing';

import { Tenent } from './pages/tenent/Tenent'
import { Landlords } from './pages/Landlords/Landlords'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="browse" element={<Browse />} />
        <Route path="properties" element={<Properties />} />
        <Route path="commercial" element={<Commercial />} />
        <Route path="student" element={<Students />} />
        <Route path="properties" element={<Properties />} />
        <Route path="contactus" element={<Contactus />} />
        <Route path="login" element={<Login />} />
        <Route path="team" element={<Team />} />
        <Route path="genaral-application" element={<GeneralApllication />} />
        <Route path="student-application" element={<StudentApplication />} />
        <Route path="Showing" element={<Showing />} />
        <Route path="Tenent" element={<Tenent />} />
        <Route path="Landlords" element={<Landlords />} />
      </Routes>
    </>
  );
}

export default App;
