import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './Showing.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LineWave } from 'react-loader-spinner';

import config from '../../config.json'

export const

    Showing = () => {
        const [isLoading, setIsloading] = useState(true)
        const [itemData, setItemData] = useState({});
        const fetchData = () => {
            axios.get(config.apiUrl + '/v1/calender/events').then((res) => {
                setItemData(res.data.groupedEvents)
                setIsloading(false)
            }).catch(() => {
                setIsloading(false)
            })
        };
        useEffect(() => {
            fetchData()
        }, []);
        useEffect(() => {
            // console.log(itemData)
        }, [itemData])
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        return (
            <>
                <KWNavbar />
                <div className="pt-5">
                    <div className="container">
                        <div className="mt-sm-3 mt-md-5 pt-1 px-2">
                            <div className="col-sm-12 col-md-10">
                                <div className="h1 pt-5 Upcoming-Propertyhead">Upcoming Property Showings</div>
                                <div className="mt-4 pt-4 Upcoming-property">Upcoming property showings are displayed below. If you would like to attend any, please click the corresponding 'want to attend' link to confirm. Don't see the property you're interested in? No problem.
                                    <a href="#" className='Upcoming-property2'> Click here to book an appointment for another property.</a>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                {
                                    isLoading !== true ?
                                        itemData ?
                                            Object.keys(itemData)?.map((key) => {
                                                const cDay = new Date(key)

                                                // console.log(itemData[key]);

                                                let d = new Date();
                                                const today = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                                                const keyDate = key.split('-').reduce((a, b) => a + b,)
                                                const td = today.split('-').reduce((a, b) => a + b,)
                                                const title = today === key ? 'Today' : keyDate === Number(td) + 1 ? 'Tomorrow' : `${daysOfWeek[cDay.getDay()]} , ${months[cDay.getMonth('m')]} ${cDay.getDate()}  ${cDay.getFullYear()}`
                                                return (
                                                    <>

                                                        <div className="secound-content mt-5">
                                                            <div className="content2-head"> {title}</div>
                                                            <div className="content2-border"></div>
                                                            <div className="content2-row">
                                                                {
                                                                    itemData[key].map((item) => {
                                                                        const labl = new Date(item.start.dateTime)
                                                                        // console.log(item);
                                                                        // console.log(labl.getMinutes());
                                                                        return (
                                                                            <div className="row mt-2">
                                                                                <div className="col-sm-12 col-md-2 time">
                                                                                    {labl.getHours() > 12 ?
                                                                                        (labl.getHours() - 12) < 10 ? `0${labl.getHours() - 12}:` : (labl.getHours() - 12) + ":" : labl.getHours() + ":"}
                                                                                    {labl.getMinutes() < 10 ? `0${labl.getMinutes()}` : labl.getMinutes()}
                                                                                    {labl.getHours() > 12 ? "  PM" : "  AM"}
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-6">
                                                                                    <a href="#" className='middle-link1'>{item.details.summery} {item.details.meetingStatus}</a>
                                                                                    {/* <div className='content2-unit'>Unit C</div> */}
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-4">
                                                                                    <a href="#" className='book-Showing' >BOOK SHOWING</a>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            : ('')
                                        : (
                                            <div className="d-flex justify-content-center">
                                                <LineWave
                                                    height="200px"
                                                    width="300px"
                                                    color="#0586f0"
                                                    ariaLabel="line-wave"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    firstLineColor=""
                                                    middleLineColor=""
                                                    lastLineColor=""
                                                />
                                            </div>
                                        )

                                }
                            </div>
                        </div>
                    </div>
                </div>
                <KWFooter />
            </>
        )
    }