import React, { useState } from 'react'
import { Collapse, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './stapp.css'
import img from "../GeneralAplication/download.png"


export const StudentApplication = () => {
    const [personalInfo, setPersonalInfo] = useState(false)
    const [importantNote1, setimportantNote1Info] = useState(false)
    // const [importantNote, setimportantNoteInfo] = useState(false)
    // const [importantNote2, setimportantNote2Info] = useState(false)
    const [RHCL, setRHCL] = useState(false)
    const [RHPL, setRHPL] = useState(false)
    const [CoSigner, setCoSigner] = useState(false)
    const [Other, setOther] = useState(false)
    const [ApplDAta, setApplDAta] = useState(false)

    const [isUnitChecked, setisUnitChecked] = useState(false);
    const [isOn, setIsOn] = useState(false);


    const handleUnitSwitchChange = () => {
        setisUnitChecked(!isUnitChecked);
        setIsOn(!isOn);
    };

    const [isPetChecked, setisPetChecked] = useState(false);
    const [anypetsisOn, setAnypetsisOn] = useState(false);

    const handlePetSwitchChange = () => {
        setisPetChecked(!isPetChecked);
        setAnypetsisOn(!anypetsisOn);
    };

    const [isBankruptcyChecked, setisBankruptcyChecked] = useState(false);
    const [DebtProposalisOn, setDebtProposalisOn] = useState(false);

    const handleBankruptcySwitchChange = () => {
        setisBankruptcyChecked(!isBankruptcyChecked);
        setDebtProposalisOn(!DebtProposalisOn);
    };

    const [isConsumerDebtProposalChecked, setisConsumerDebtProposalChecked] = useState(false);
    const [filedBankruptcyisOn, setfiledBankruptcyisOn] = useState(false);

    const handleConsumerDebtProposalSwitchChange = () => {
        setisConsumerDebtProposalChecked(!isConsumerDebtProposalChecked);
        setfiledBankruptcyisOn(!filedBankruptcyisOn);
    };

    const [isBillsOnTimeChecked, setisBillsOnTimeChecked] = useState(false);
    const [billsOnisOn, setbillsOnisOn] = useState(false);

    const handleBillsOnTimeSwitchChange = () => {
        setisBillsOnTimeChecked(!isBillsOnTimeChecked);
        setbillsOnisOn(!billsOnisOn);
    };
    const [isAfterYouForChecked, setisAfterYouForhecked] = useState(false);
    const [forMoneyisOn, setforMoneyisOn] = useState(false);
    
    const handleAfterYouForSwitchChange = () => {
        setisAfterYouForhecked(!isAfterYouForChecked);
        setforMoneyisOn(!forMoneyisOn);
    };
    const [isAcoSignerChecked, setisAcoSignerhecked] = useState(false);
    const [coSignerisOn, setcoSignerisOn] = useState(false);
    
    const handleAcoSignerSwitchChange = () => {
        setisAcoSignerhecked(!isAcoSignerChecked);
        setcoSignerisOn(!coSignerisOn);
    };
    const [isApplyingWithChecked, setisApplyingWithhecked] = useState(false);
    const [AgroupisOn, setAgroupisOn] = useState(false);
    
    const handleApplyingWithSwitchChange = () => {
        setisApplyingWithhecked(!isApplyingWithChecked);
        setAgroupisOn(!AgroupisOn);
    };


    const handleCollapse = (from) => {
        if (from === 'importantNote1') {
            setimportantNote1Info(!importantNote1);
        }
        // if (from === 'importantNote') {
        //     setimportantNoteInfo(!importantNote);
        // }
        // if (from === 'importantNote2') {
        //     setimportantNote2Info(!importantNote2);
        // }
        if (from === 'personalInfo') {
            setPersonalInfo(!personalInfo);
        }
        if (from === 'RHCL') {
            setRHCL(!RHCL)
        }
        if (from === 'RHPL') {
            setRHPL(!RHPL)
        }
        if (from === 'Co-Signer') {
            setCoSigner(!CoSigner)
        }
        if (from === 'Other') {
            setOther(!Other)
        }
        if (from === 'ApplDAta') {
            setApplDAta(!ApplDAta)
        }
    }

    let date = new Date();
    const year = function () {
        const yy = [];
        for (let y = date.getFullYear(); y >= 1904; y--) {
            yy.push(y);
        }
        return yy
    }
    const day = function () {
        const dy = [];
        for (var i = 1; i <= 31; i++) {
            dy.push(i);
        }
        return dy
    }
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const data = year();
    const d = day();
    return (
        <>
            <KWNavbar />
            <div style={{ backgroundColor: "#eee" }} className='h-100 mt-4'>
                <div className='mt-2 pt-5 container'>
                    <Row>
                        <div className='col-12 justify-content-center align-items-center m-auto mb-4 student-main-margin' >
                            <br />
                            {/* <div
                                style={{ border: "2px solid #337ab7" }}
                                className="row m-1 d-flex rounded p-3 bg-white">
                                <p className='p-1'>
                                    <img style={{ width: "15px" }} src={img} alt='' />
                                    <span
                                        style={{ color: "#333", fontWeight: 'bold', lineHeight: 1.42857143, marginBottom: 5, }}
                                    >To speed up the application process, we require every applicant that is over 18 years old to provide a full credit report, reflecting the score. Please see below for some free & paid websites to pull your own credit report and self-submit. Thank-you, The Management.</span>
                                </p>
                                <div className='row text-decoration-none' >
                                    <div className='col-xs-6 col-md-4'><a href="https://www.creditkarma.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.creditkarma.ca</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://borrowell.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.borrowell.com</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://www.mogo.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.mogo.ca</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://www.equifax.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.equifax.ca</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://www.transunion.ca" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.transunion.ca</a></div>
                                </div>
                            </div><br /> */}

                            <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer", border: "1px solid red" }}
                                    onClick={() => handleCollapse('importantNote1')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2 text-danger'>Important Note</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('importantNote1')}
                                            style={{ cursor: "pointer", color: "#A94442" }} className=''>
                                            {
                                                importantNote1 ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={importantNote1}>
                                <div style={{ backgroundColor: "white" }} className="row p-2 border-2 rounded ">
                                    <div className="h-auto mb-2">
                                        <div
                                            style={{ border: "2px solid #8a6d3b" }}
                                            className="row m-1 d-flex rounded p-3 bg-white">
                                            <p className='p-1'>
                                                <img style={{ width: "15px" }} src={img} alt='' />
                                                <span
                                                    style={{ color: "#8a6d3b" }}
                                                    className='px-1 py-2'>All units require tenant(s) to have an insurance policy at move-in for all leaseholders. If you don't already have an insurance provider, feel free to use our unique link below for a discounted policy : <a href={'https://www.squareone.ca'}>https://www.squareone.ca</a></span>
                                            </p>
                                        </div><br />
                                    </div>
                                    <div className="h-auto mb-2">
                                        <div
                                            style={{ border: "2px solid #A94442" }}
                                            className="row m-1 d-flex rounded p-3 bg-white">
                                            <p className='p-1'>
                                                <img style={{ width: "15px" }} src={img} alt='' />
                                                <span
                                                    style={{ color: "#A94442" }}
                                                    className='px-1 py-2'>Please note that all applicants must view the unit in order to process your application. A deposit will be required upon approval of application and instructions will be shared with you at that time.</span>
                                            </p>
                                        </div><br />
                                    </div>

                                </div>
                            </Collapse>

                            <br />

                            {/* <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleCollapse('importantNote')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2'>Important Note</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('importantNote')}
                                            style={{ cursor: "pointer" }} className=''>
                                            {
                                                importantNote ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div> */}

                            {/* <Collapse in={importantNote}>
                            <div style={{ backgroundColor: "white" }} className="row p-3 border-2 rounded ">
                                    <div className="col-md-12 h-auto mb-2">
                                        <div
                                            style={{ border: "2px solid #A94442" }}
                                            className="row m-1 d-flex rounded p-3 bg-white">
                                            <p className='p-1'>
                                                <img style={{ width: "15px" }} src={img} alt='' />
                                                <span
                                                    style={{ color: "#A94442" }}
                                                    className='px-1 py-2'>All units require tenant(s) to have an insurance policy at move-in for all leaseholders. If you don't already have an insurance provider, feel free to use our unique link below for a discounted policy: <a href={'https://www.squareone.ca'}>https://www.squareone.ca</a></span>
                                            </p>
                                        </div><br />
                                    </div>

                                </div>
                                <div style={{ backgroundColor: "white" }} className="row p-3 border-2 rounded ">
                                    <div className="col-md-12 h-auto mb-2">
                                        <div
                                            style={{ border: "2px solid #A94442" }}
                                            className="row m-1 d-flex rounded p-3 bg-white">
                                            <p className='p-1'>
                                                <img style={{ width: "15px" }} src={img} alt='' />
                                                <span
                                                    style={{ color: "#A94442" }}
                                                    className='px-1 py-2'>Please note that all applicants must view the unit in order to process your application. A deposit will be required upon approval of application and instructions will be shared with you at that time.</span>
                                            </p>
                                        </div>
                                        <br />
                                    </div>

                                </div>
                            </Collapse> */}

                            <div style={{ backgroundColor: "white" }} className='form-content py-1 px-3 rounded my-4'>
                                {/* <h1>General <span className='blue'>Application</span></h1> */}
                                <div className='my-3'>
                                    <div className="form-group my-3">
                                        <label className='py-2 fw-bold' htmlFor="exampleInputEmail1">Preferred Move in Date</label>
                                        <input type="date" name='dteFormat' className="required-number required-number2 w-100" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="DD/MM/YYYY" />
                                    </div>
                                    <div className="form-group my-3">
                                        <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Please specify the property <span style={{ backgroundColor: "#fcf8e3" }}>ADDRESS & UNIT # </span> you're interested in:</label>
                                        <textarea className='required-number required-number2 w-100' placeholder='Property address' name="abc" id="xyz" >

                                        </textarea>
                                    </div>
                                    <div className="form-group my-3">
                                        <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Offered Rent Amount <small style={{ color: "rgba(1,1,1,0.4)", fontSize: "11px" }}> (optional) </small></label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">$</div>
                                            </div>
                                            <input type="text" className="form-control required-number required-number2" id="inlineFormInputGroup" placeholder="0.00" />
                                        </div>
                                    </div>
                                    <div className="form-group my-3">
                                        <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Have you viewed the unit?</label><br />
                                        <Form className='d-flex'>
                                            <Form.Check
                                                className='me-1'
                                                type="switch"
                                                id="custom-switch"
                                                checked={isUnitChecked}
                                                onChange={handleUnitSwitchChange}
                                            />{isOn ? 'Yes' : 'No'}
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleCollapse('personalInfo')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2'>Personal Information</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('personalInfo')}
                                            style={{ cursor: "pointer" }} className=''>
                                            {
                                                personalInfo ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <Collapse in={personalInfo}>
                                <div style={{ backgroundColor: "white" }} className="row p-3 border-2 rounded ">
                                    <div className="col-sm-12 col-md-6 h-auto mb-2">
                                        <label htmlFor="validationCustom01" className='py-2'><b>Required number of parking spaces?</b></label>
                                        <div className="form-group-inline">
                                            <select className="w-100 required-number required-number2" style={{}} id="NumberOfVehicles" name="NumberOfVehicles">
                                                <option value=""></option>
                                                <option value="0">None</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10+">10+</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4 mb-3">
                                    <label htmlFor="validationCustom02" className='py-2  fw-bold'>Family size to occupy home</label>
                                    <div className="form-group-inline d-flex">
                                        <label className='form-group'>Adults</label>
                                        <div className="col-md-8 mx-1 d-flex">
                                            <select style={{ width: "30%" }} className="" name="" id="">
                                                <option value=""></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <label className='p-2' htmlhtmlFor="">Adults</label>
                                        </div>
                                        <div className="col-md-8 mx-1 d-flex">
                                            <select style={{ width: "30%" }} className="" name="" id="">
                                                <option value=""></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <label className='p-2' htmlhtmlFor="">Childrens</label>
                                        </div>
                                    </div>

                                </div> */}
                                    <div className="form-group my-1">
                                        <label className='py-2' htmlFor="exampleInputPassword1"><b>Do you own any pets?</b></label><br />
                                        <Form>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={isPetChecked}
                                                onChange={handlePetSwitchChange}
                                            />{anypetsisOn ? 'Yes' : 'No'}
                                        </Form>
                                    </div>
                                </div>
                            </Collapse>
                            <br />
                            <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleCollapse('RHCL')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2'>Rental History - Current Location</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('RHCL')}
                                            style={{ cursor: "pointer" }} className=''>
                                            {
                                                RHCL ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={RHCL}>
                                <div style={{ backgroundColor: "white" }} className="row mb-4 rounded p-3">
                                    <div className="form-group mb-4">
                                        <select className="w-100 rounded py-2 px-1 required-number" id="CurrentLocationStatus" name="CurrentLocationStatus">
                                            <option value="">Please specify...</option>
                                            <option value="I am currently a home owner">I am currently a home owner</option>
                                            <option value="I currently live with family">I currently live with family</option>
                                            <option value="renting">I am currently renting</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </Collapse>
                            <br />
                            <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleCollapse('RHPL')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2'>Rental History - Past Location</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('RHPL')}
                                            style={{ cursor: "pointer" }} className=''>
                                            {
                                                RHPL ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={RHPL}>
                                <div style={{ backgroundColor: "white" }} className="row pb-4 p-3 rounded">
                                    <div className="">
                                        If you rented in the past 3 years at a location other than your current location, please specify below.
                                    </div>

                                    <div className="form-group">
                                        <label className='py-1' htmlFor="PreviousAddress2"><b>Previous address</b></label>
                                        <input className="form-control required-number required-number2" id="PreviousAddress2" maxlength="255" name="PreviousAddress2" type="text" />
                                    </div>
                                    <div className="d-sm-block d-md-flex">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="form-group">
                                                <label className='py-1' htmlFor="PreviousLandlordPhone2"><b>Previous Landlord's Phone</b></label>
                                                <div className="input-group p-1">
                                                    <span className="input-group-text">
                                                        <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                            alt="" className='rentalHPlocation' />
                                                    </span>
                                                    <input className="form-control required-number required-number2" id="PreviousLandlordPhone2" maxlength="50" name="PreviousLandlordPhone2" type="text" />
                                                </div>
                                                <div className="error-msgs"></div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <label className='py-1' htmlFor="LandlordFirstName2"><b>Previous Landlord's Name</b></label>
                                            <div className="row p-1">
                                                <div className="col-sm-12 col-md-6 pb-2">
                                                    <input className="form-control required-number required-number2" id="PreviousLandlordFirstName2" maxlength="25" name="PreviousLandlordFirstName2" placeholder="First" type="text" />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <input className="form-control required-number required-number2" id="PreviousLandlordLastName2" maxlength="25" name="PreviousLandlordLastName2" placeholder="Last" type="text" />
                                                </div>
                                                <div className="error-msgs"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group optional">
                                        <label className='py-1' htmlFor="LandlordEmailAddress2"><b className='fw-bold'>Previous Landlord's Email address</b> <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                        <input className="form-control required-number required-number2" id="PreviousLandlordEmailAddress2" maxlength="80" name="PreviousLandlordEmailAddress2" placeholder="Enter email address" type="email" />
                                        <div className="error-msgs"></div>
                                    </div>
                                </div>
                            </Collapse>
                            <br />
                            <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleCollapse('ApplDAta')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2'>Applicant Details</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('ApplDAta')}
                                            style={{ cursor: "pointer" }} className=''>
                                            {
                                                ApplDAta ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={ApplDAta}>
                                <div id="applicant-wrapper" style={{ backgroundColor: "white" }} className="p-3 row rounded">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="App2FirstName" className='fw-bold'>Name</label>
                                                <div className="row">
                                                    <div className="col-sm-6 mb-2">
                                                        <input
                                                            className="required-number required-number2 w-100"
                                                            id="Applicant_FirstName"
                                                            maxLength={25}
                                                            name="Applicant.FirstName"
                                                            placeholder="First"
                                                            type="text"
                                                            defaultValue=""
                                                            fdprocessedid="cqyqzj"
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 mb-2">
                                                        <input
                                                            className="required-number required-number2 w-100"
                                                            id="Applicant_LastName"
                                                            maxLength={25}
                                                            name="Applicant.LastName"
                                                            placeholder="Last"
                                                            type="text"
                                                            defaultValue=""
                                                            fdprocessedid="v4zfp"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="error-msgs" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="birthday" className='fw-bold'>Date of Birth</label>
                                                <div className="d-flex">
                                                    <div className="col mx-1">
                                                        <select
                                                            className="w-100 rounded-1 required-number required-number2"
                                                            id="Applicant_BirthYear"
                                                            name="Applicant.BirthYear"
                                                            fdprocessedid="k0xgaq"
                                                        >
                                                            <option value="">YY</option>
                                                            {
                                                                data.map((y, index) => {
                                                                    return (
                                                                        <>
                                                                            <option key={index} value={y}>{y}</option>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col mx-1">
                                                        <select
                                                            className="w-100 rounded-1 required-number required-number2"
                                                            id="Applicant_BirthMonth"
                                                            name="Applicant.BirthMonth"
                                                            fdprocessedid="tqtrbk"
                                                        >
                                                            <option value="">MM</option>
                                                            {
                                                                month.map((name, index) => {
                                                                    return <><option value={index + 1}>{name}</option></>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col mx-1">
                                                        <select
                                                            className="w-100 rounded-1  required-number required-number2"
                                                            id="Applicant_BirthDay"
                                                            name="Applicant.BirthDay"
                                                            fdprocessedid="a6p3i"
                                                        >
                                                            <option value="">DD</option>
                                                            {
                                                                d.map((d, index) => {
                                                                    return <><option key={index} value={d}>{d}</option></>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="error-msgs" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="HomePhone" className='fw-bold'>Home Phone</label>
                                                <div className="input-group">
                                                    <span className="input-group-text">
                                                        <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                            alt="" className='rentalHPlocation' />
                                                    </span>
                                                    <input
                                                        className="form-control required-number required-number2 phone-group"
                                                        id="Applicant_Phone"
                                                        maxLength={30}
                                                        name="Applicant.Phone"
                                                        placeholder="(555)-555-5555"
                                                        type="text"
                                                        defaultValue=""
                                                        fdprocessedid="9wti6s"
                                                    />
                                                </div>
                                                <div className="error-msgs" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="CellPhone" className='fw-bold'>Cell Phone</label>
                                                <div className="input-group">
                                                    <span className="input-group-text">
                                                        <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                            alt="" className='rentalHPlocation' />
                                                    </span>
                                                    <input
                                                        className="form-control required-number required-number2 phone-group"
                                                        id="Applicant_CellPhone"
                                                        maxLength={30}
                                                        name="Applicant.CellPhone"
                                                        placeholder="(555)-555-5555"
                                                        type="text"
                                                        defaultValue=""
                                                        fdprocessedid="j93t4d"
                                                    />
                                                </div>
                                                <div className="error-msgs" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group required mt-1">
                                                <label htmlFor="email" className='fw-bold'>Email address</label>
                                                <div>
                                                    <input
                                                        className="required-number required-number2 w-100"
                                                        id="Applicant_EmailAddress"
                                                        maxLength={80}
                                                        name="Applicant.EmailAddress"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        defaultValue=""
                                                        fdprocessedid="t4inew"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group optional">
                                                <label htmlFor="inputAddress" className='fw-bold'>Current Address <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                <input
                                                    className="required-number required-number2 w-100"
                                                    id="Applicant_CurrentAddress"
                                                    maxLength={150}
                                                    name="Applicant.CurrentAddress"
                                                    placeholder="123 Main St."
                                                    type="text"
                                                    defaultValue=""
                                                    fdprocessedid="nbtgbk"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group optional">
                                                <label htmlFor="inputAddress2" className='fw-bold'>Current Address 2 <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                <input
                                                    className="required-number required-number2 w-100"
                                                    id="Applicant_CurrentAddress2"
                                                    maxLength={150}
                                                    name="Applicant.CurrentAddress2"
                                                    placeholder="Apartment, studio, or floor"
                                                    type="text"
                                                    defaultValue=""
                                                    fdprocessedid="kopl3k"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="inputCity" className='fw-bold'>City</label>
                                            <br />
                                            <input
                                                className="required-number required-number2 w-100"
                                                id="Applicant_CurrentCity"
                                                maxLength={50}
                                                name="Applicant.CurrentCity"
                                                type="text"
                                                defaultValue=""
                                                fdprocessedid="x0jqsk"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="inputState" className='fw-bold'>State</label>
                                            <input
                                                className="required-number required-number2 w-100"
                                                id="Applicant_CurrentProvince"
                                                maxLength={50}
                                                name="Applicant.CurrentProvince"
                                                type="text"
                                                defaultValue=""
                                                fdprocessedid="8sm8gg"
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-2">
                                            <label htmlFor="inputZip" className='fw-bold'>Postal</label>
                                            <input
                                                className="required-number required-number2 w-100"
                                                id="Applicant_CurrentPostal"
                                                maxLength={10}
                                                name="Applicant.CurrentPostal"
                                                type="text"
                                                defaultValue=""
                                                fdprocessedid="5aahbb"
                                            />
                                            <div className="error-msgs" />
                                        </div>
                                        <div className="error-msgs" />
                                    </div>
                                    <div className="row" style={{ marginTop: 40 }}>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="App2PlaceOfEmployment" className='fw-bold'>Place of Employment</label>
                                                <div>
                                                    <input
                                                        className="required-number required-number2 w-100"
                                                        id="Applicant_PlaceOfEmployment"
                                                        maxLength={80}
                                                        name="Applicant.PlaceOfEmployment"
                                                        type="text"
                                                        defaultValue=""
                                                        fdprocessedid="nn00ff"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="App2Occupation" className='fw-bold'>Occupation</label>
                                                <div>
                                                    <input
                                                        className="required-number required-number2 w-100"
                                                        id="Applicant_Occupation"
                                                        maxLength={80}
                                                        name="Applicant.Occupation"
                                                        type="text"
                                                        defaultValue=""
                                                        fdprocessedid="g89kda"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="email" className='fw-bold'>Length of employment</label>
                                                <div className="d-flex">
                                                    <div className="col-xs-6 m-1 error-helper">
                                                        <div className="d-flex">
                                                            <input
                                                                className="rounded col-6 required-number required-number2"
                                                                id="Applicant_EmploymentLengthYears"
                                                                maxLength={2}
                                                                name="Applicant.EmploymentLengthYears"
                                                                placeholder="YY"
                                                                type="text"
                                                                defaultValue=""
                                                                fdprocessedid="49bouq"
                                                            />
                                                            <label className='m-1' htmlFor="email">Years</label>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-6 m-1 error-helper">
                                                        <div className="d-flex">
                                                            <input
                                                                className="rounded col-6 required-number required-number2"
                                                                id="Applicant_EmploymentLengthMonths"
                                                                maxLength={2}
                                                                name="Applicant.EmploymentLengthMonths"
                                                                placeholder="MM"
                                                                type="text"
                                                                defaultValue=""
                                                                fdprocessedid="oglyma"
                                                            />
                                                            <label className='m-1' htmlFor="email">Months</label>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group optional">
                                                <label htmlFor="App2EmployerPhone" className='fw-bold'>Employer's Telephone <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                <div>
                                                    <input
                                                        className="required-number required-number2 w-100 mt-1"
                                                        id="Applicant_EmployerPhone"
                                                        maxLength={80}
                                                        name="Applicant.EmployerPhone"
                                                        type="text"
                                                        defaultValue=""
                                                        fdprocessedid="uiifit"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="App2HourlyWage" className='fw-bold'>Hourly Wage <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input type="text" className="form-control required-number required-number2" placeholder="0.00" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="App2GrossWage" className='fw-bold'>Gross Yearly</label>
                                                <div className="input-group d-flex mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input type="text" className="form-control required-number required-number2" placeholder="0.00" />
                                                </div>
                                                <div className="error-msgs" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group optional">
                                                <label htmlFor="App2SIN" className='fw-bold'>S.I.N. No. <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    className="required-number required-number2 w-100"
                                                    id="Applicant_SIN"
                                                    maxLength={15}
                                                    name="Applicant.SIN"
                                                    type="text"
                                                    defaultValue=""
                                                    fdprocessedid="9da81e"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <label htmlFor="" className='fw-bold'>Number of vehicles owned by this applicant?</label>
                                            <div className="form-group">
                                                <select
                                                    id="applicant_vehicles"
                                                    className="required-number required-number2 w-100 applicant-vehicles"
                                                    required=""
                                                    fdprocessedid="msnax"
                                                >
                                                    <option value="" />
                                                    <option value={0}>0</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="applicant-vehicle-inputs" />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className='fw-bold'>Have you ever filed Bankruptcy?</label>
                                            <Form className='d-flex'>
                                                <Form.Check
                                                    className='me-1'
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={isConsumerDebtProposalChecked}
                                                    onChange={handleConsumerDebtProposalSwitchChange}
                                                /> {filedBankruptcyisOn ? 'Yes' : 'No'}
                                            </Form>
                                            {/* <div className="form-group">
                                                <label className="radio-inline">
                                                    <input
                                                        id="Applicant_Bankruptcy"
                                                        name="Applicant.Bankruptcy"
                                                        type="radio"
                                                        defaultValue="True"
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        id="Applicant_Bankruptcy"
                                                        name="Applicant.Bankruptcy"
                                                        type="radio"
                                                        defaultValue="False"
                                                    />{" "}
                                                    No
                                                </label>
                                                <div className="error-msgs" />
                                            </div> */}
                                        </div>
                                        <div className="col-sm-6">
                                            <label className='fw-bold'>Have you ever done a Consumer Debt Proposal?</label>
                                            <div className="form-group">
                                                <Form className='d-flex'>
                                                    <Form.Check
                                                        className='me-1'
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={isBankruptcyChecked}
                                                        onChange={handleBankruptcySwitchChange}
                                                    /> {DebtProposalisOn ? 'Yes' : 'No'}
                                                </Form>
                                                {/* <label className="radio-inline">
                                                    <input
                                                        id="Applicant_DebtProposal"
                                                        name="Applicant.DebtProposal"
                                                        type="radio"
                                                        defaultValue="True"
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        id="Applicant_DebtProposal"
                                                        name="Applicant.DebtProposal"
                                                        type="radio"
                                                        defaultValue="False"
                                                    />{" "}
                                                    No
                                                </label> */}
                                                <div className="error-msgs" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className='fw-bold'>Do you pay your bills on time?</label>
                                            <Form className='d-flex'>
                                                <Form.Check
                                                    className='me-1'
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={isBillsOnTimeChecked}
                                                    onChange={handleBillsOnTimeSwitchChange}
                                                /> {billsOnisOn ? 'Yes' : 'No'}
                                            </Form>
                                            {/* <div className="form-group">
                                                <label className="radio-inline">
                                                    <input
                                                        id="Applicant_BillsOnTime"
                                                        name="Applicant.BillsOnTime"
                                                        type="radio"
                                                        defaultValue="True"
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        id="Applicant_BillsOnTime"
                                                        name="Applicant.BillsOnTime"
                                                        type="radio"
                                                        defaultValue="False"
                                                    />{" "}
                                                    No
                                                </label>
                                                <div className="error-msgs" />
                                            </div> */}
                                        </div>
                                        <div className="col-sm-6">
                                            <label className='fw-bold'>Has anyone ever come after you for money?</label>
                                            <Form className='d-flex'>
                                                <Form.Check
                                                    className='me-1'
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={isAfterYouForChecked}
                                                    onChange={handleAfterYouForSwitchChange}
                                                /> {forMoneyisOn ? 'Yes' : 'No'}
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <label className='fw-bold'>
                                                Please upload your Photo ID (We accept images and PDF's).{" "}
                                            </label>
                                            <div style={{ position: "relative" }} className="form-group">
                                                <div style={{ height: 0, overflow: "hidden", width: "100%" }}>
                                                    <input
                                                        type="text"
                                                        id="applicant-document-count"
                                                        name="applicant-document-count"
                                                        defaultValue={0}
                                                        className="document-count w-100"
                                                        fdprocessedid="3yk7cj"
                                                    />
                                                </div>
                                                <div

                                                    className="filepond--root my-pond w-100 border filepond--hopper"
                                                    id="applicant-documents"
                                                    data-style-button-remove-item-position="left"
                                                    data-style-button-process-item-position="right"
                                                    data-style-load-indicator-position="right"
                                                    data-style-progress-indicator-position="right"
                                                    data-style-button-remove-item-align="false"
                                                    style={{ height: 76, position: "relative" }}
                                                >
                                                    <input
                                                        style={{}}
                                                        className="filepond--browser"
                                                        type="file"
                                                        id="filepond--browser-wk65r38pt"
                                                        name="applicant-documents"
                                                        aria-controls="filepond--assistant-wk65r38pt"
                                                        aria-labelledby="filepond--drop-label-wk65r38pt"
                                                        accept="image/gif,image/png,image/jpeg,application/pdf"
                                                        multiple=""
                                                    />
                                                    <div
                                                        className="filepond--drop-label"
                                                        style={{ transform: "translate3d(0px, 0px, 0px)", opacity: 1 }}
                                                    >
                                                        <label
                                                            htmlFor="filepond--browser-wk65r38pt"
                                                            id="filepond--drop-label-wk65r38pt"
                                                            aria-hidden="true"
                                                        >
                                                            Drag &amp; Drop your files or{" "}
                                                            <span className="filepond--label-action" tabIndex={0}>
                                                                Browse
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="filepond--list-scroller"
                                                        style={{ transform: "translate3d(0px, 60px, 0px)" }}
                                                    >
                                                        {/* <ul className="filepond--list" role="list" /> */}
                                                        <ul className="filepond--list" />
                                                    </div>
                                                    <div
                                                        className="filepond--panel filepond--panel-root"
                                                        data-scalable="true"
                                                    >
                                                        <div className="filepond--panel-top filepond--panel-root" />
                                                        <div
                                                            className="filepond--panel-center filepond--panel-root"
                                                            style={{
                                                                transform: "translate3d(0px, 8px, 0px) scale3d(1, 0.6, 1)"
                                                            }}
                                                        />
                                                        <div
                                                            className="filepond--panel-bottom filepond--panel-root"
                                                            style={{ transform: "translate3d(0px, 68px, 0px)" }}
                                                        />
                                                    </div>
                                                    <span
                                                        className="filepond--assistant"
                                                        id="filepond--assistant-wk65r38pt"
                                                        role="status"
                                                        aria-live="polite"
                                                        aria-relevant="additions"
                                                    />
                                                    <fieldset className="filepond--data" />
                                                    <div className="filepond--drip" />
                                                </div>
                                                <div className="error-msgs" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>

                            <br />
                            <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleCollapse('Co-Signer')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2'>Co-Signer</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('Co-Signer')}
                                            style={{ cursor: "pointer" }} className=''>
                                            {
                                                CoSigner ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={CoSigner}>
                                <div style={{ backgroundColor: "white" }} className="row rounded">
                                    <div className="form-group my-3">
                                        <label className='py-2' htmlFor="exampleInputPassword1"><b>Will there be a co-signer?</b></label><br />
                                        <Form className='d-flex'>
                                            <Form.Check
                                                className='me-1'
                                                type="switch"
                                                id="custom-switch"
                                                checked={isAcoSignerChecked}
                                                onChange={handleAcoSignerSwitchChange}
                                            /> {coSignerisOn ? 'Yes' : 'No'}
                                        </Form>
                                    </div>
                                </div>
                            </Collapse>
                            <br />
                            <div className='row border rounded-top'>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleCollapse('Other')}
                                    className="form-content p-2 d-flex justify-content-between rounded">
                                    <div>
                                        <h4 className='py-2'>Other</h4>
                                    </div>
                                    <div>
                                        <h2
                                            onClick={() => handleCollapse('Other')}
                                            style={{ cursor: "pointer" }} className=''>
                                            {
                                                Other ? ("−") : ("+")
                                            }
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={Other}>
                                <div style={{ backgroundColor: "white" }} className="row rounded">
                                    <div className="form-group pt-3">
                                        <label htmlFor="exampleInputPassword1"><b>Are you applying with a group?</b></label><br />
                                        <div className='mt-2'>
                                            <Form className='d-flex'> 
                                                <Form.Check
                                                    className='me-1'
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={isApplyingWithChecked}
                                                    onChange={handleApplyingWithSwitchChange}
                                                /> {AgroupisOn ? 'Yes' : 'No'}
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="form-group my-3">
                                        <label className='py-2' htmlFor="exampleInputPassword1"><b>Additional Expectations</b></label><br />
                                        <div className='pb-2'>
                                            Please note, all units are rented 'as is'. Any alteration requests must be put in writing during the application process and will be submitted to the owner for approval. List any and all additional expectations that you feel need to be addressed prior to moving in. We will do our best to accommodate you, but we do not make any guarantees, whatsoever. Thank-you, The Management.
                                        </div>
                                        <div className='mt-2 pb-3'>
                                            <textarea name="option1" id="exampleInputPassword1" cols="20" rows="3" className='required-number required-number2 w-100'></textarea>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <div
                                style={{ border: "2px solid #337ab7" }}
                                className="row mt-4 d-flex rounded p-3 bg-white">
                                <p className='p-1'>
                                    {/* <img style={{ width: "15px" }} src={img} alt='' /> */}
                                    <span
                                        style={{ color: "#333", fontWeight: 'bold', lineHeight: 1.42857143, marginBottom: 5, }}
                                    >To speed up the application process, we require every applicant that is over 18 years old to provide a full credit report, reflecting the score. Please see below for some free & paid websites to pull your own credit report and self-submit. Thank-you, The Management.</span>
                                </p>
                                <div className='row text-decoration-none' >
                                    <div className='col-xs-6 col-md-4'><a href="https://www.creditkarma.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.creditkarma.ca</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://borrowell.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.borrowell.com</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://www.mogo.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.mogo.ca</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://www.equifax.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.equifax.ca</a></div>
                                    <div className='col-xs-6 col-md-4'><a href="https://www.transunion.ca" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.transunion.ca</a></div>
                                </div>
                            </div><br />
                            <div className='my-3'>
                                <button className='btn btn-primary'>Submit Application</button>
                            </div>

                        </div>
                    </Row>

                </div>
            </div>
            <KWFooter />
        </>
    )
}
